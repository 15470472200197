import React, { useState, useEffect } from 'react';
import './PhotoGearWizard.css'; // Assuming you have a CSS file for styles
// import recommendations from '../data/recommendations'; // Adjust the path as necessary
import categories from '../data/streamlinedCategories'; // Adjust the path as necessary
import { getStreamlinedRecs } from '../data/streamlinedRecs.js';


const PhotoGearWizard = () => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [recommendations, setRecommendations] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  

  useEffect(() => {
    const newRecommendations = getStreamlinedRecs(selectedOptions);
    setRecommendations(newRecommendations);
    console.log("recommendations", recommendations);
  }, [selectedOptions]);

  const handleClick = (categoryName, optionValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [categoryName]: optionValue,
    }));
    // Automatically move to the next step, but not beyond the categories length
    const nextStep = currentStep < categories.length ? currentStep + 1 : currentStep;
    setCurrentStep(nextStep);
  };

  console.log('Rendering component');
  console.log('categories: ', categories);
  console.log('categories[0]: ', categories[0]);
  console.log('selectedOptions:', JSON.stringify(selectedOptions));
  console.log('streamlinedRecs:', getStreamlinedRecs(selectedOptions));
  
  const resetSelections = () => {
    setSelectedOptions({});
    setCurrentStep(0);
  };

  const currentCategory = categories[currentStep];

  return (    
    <div className="wizard-container">
      <div className="content-container">
        {currentStep < categories.length && (
          <div key={currentCategory.name} className="bubble-container">
            <h2>{currentCategory.name}</h2>
            <div className="description">
              {categories[currentStep].description}
            </div>
            {currentCategory.options.map((option) => (
              <div
                key={option.value}
                className={`bubble ${selectedOptions[currentCategory.name] === option.value ? 'selected' : ''}`}
                onClick={() => handleClick(currentCategory.name, option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}       

        {Object.keys(selectedOptions).length === categories.length && (
          <div class="recommendations-wrapper">
            <div className="recommendations-container">
              <h2>Recommended Gear:</h2>
              {recommendations.camera && <p>Camera: {recommendations.camera}</p>}
              {recommendations.lens && <p>Lens: {recommendations.lens}</p>}
              {recommendations.lighting && <p>Lighting: {recommendations.lighting}</p>}
              {recommendations.tripod && <p>Tripod: {recommendations.tripod}</p>}
              {/* Render other recommendations as needed */}
              {(!recommendations || !recommendations.camera) && (
                <p>No recommendations available for the selected options.</p>
              )}
            </div>
          </div>
        )}

        <div className="selectedOptionsSidebar">
          <h2>Selected Options</h2>
          {Object.entries(selectedOptions).map(([categoryName, optionValue]) => {
            // Find the category object by name
            const category = categories.find(cat => cat.name === categoryName);
            // Find the option object by value within the found category
            const option = category?.options.find(opt => opt.value === optionValue);
            // Use the option's label if found, otherwise fallback to displaying the value
            const displayText = option ? option.label : optionValue;
            
            return (
              <p key={categoryName}>{`${categoryName}: ${displayText}`}</p>
            );
          })}
        </div>
      
       
      </div>
        {/* Navigation Buttons */}
        {currentStep > 0 && (
          <button onClick={() => setCurrentStep(currentStep - 1)}>
            Previous
          </button>
        )}
        {currentStep < categories.length && (
          <button onClick={() => setCurrentStep(currentStep + 1)}>
            Next
          </button>
        )}    
        <button onClick={resetSelections}>
          Reset Selections
        </button>

    </div>
  );
};

export default PhotoGearWizard;
