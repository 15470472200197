const categories = [
    {
      name: 'Subject Matter',
      description: "The subject matter you plan to photograph plays a significant role in choosing the right gear. Here's a brief guide for each option:\n\nPeople (Portraits, Weddings, Events): For capturing people, consider cameras with good low-light performance, fast and accurate autofocus, and lenses with wide apertures (e.g., f/1.8 or f/2.8) for pleasant background blur. Portrait lenses (50mm to 85mm) and zoom lenses (24-70mm) are popular choices.\n\nNature (Landscapes, Wildlife): For nature photography, prioritize cameras with high resolution and good dynamic range. Wide-angle lenses (14mm to 35mm) are ideal for landscapes, while telephoto lenses (100mm to 600mm) are essential for wildlife. Consider features like weather sealing and fast autofocus for outdoor shooting.\n\nUrban (Street, Architecture): For urban photography, look for cameras with good low-light capabilities and lenses with wide apertures for handling various lighting conditions. Wide-angle lenses (14mm to 35mm) are great for architecture, while standard lenses (35mm to 50mm) are suitable for street photography. Consider compact and discreet cameras for candid shots.\n\nSports/Action: For capturing fast-moving subjects, prioritize cameras with high-speed continuous shooting, fast and accurate autofocus, and good buffer capacity. Telephoto lenses (100mm to 400mm) are crucial for sports, while wide-angle lenses (16mm to 35mm) can be used for action shots with a broader perspective. Look for lenses with image stabilization to minimize blur.\n\nStill Life (Food, Products): For still life photography, consider cameras with high resolution and good color reproduction. Macro lenses (60mm to 100mm) are ideal for close-up shots, while standard lenses (35mm to 50mm) work well for wider compositions. Prioritize lenses with sharp optics and good manual focus control. Tripods and lighting equipment are also important for studio setups.",
      options: [
        { label: 'People (Portraits, Weddings, Events)', value: 'People' },
        { label: 'Nature (Landscapes, Wildlife)', value: 'Nature' },
        { label: 'Urban (Street, Architecture)', value: 'Urban' },
        { label: 'Sports/Action', value: 'SportsAction' },
        { label: 'Still Life (Food, Products)', value: 'StillLife' },
        // Add other subject matter options as needed
      ],
    },
    {
      name: 'Lighting Environment',
      description: "The lighting environment you'll be shooting in is an important factor when selecting photography gear. Here's a brief guide for each option:\n\nMostly Outdoors (Natural Light): When shooting primarily outdoors, prioritize cameras with good dynamic range to handle high-contrast scenes. Look for lenses with high-quality optics and coatings to minimize glare and chromatic aberration. Consider investing in polarizing filters to reduce reflections and enhance colors. If shooting in bright sunlight, a lens hood can help prevent lens flare.\n\nMostly Indoors (Artificial Light): For indoor photography, consider cameras with good low-light performance and high ISO capabilities. Fast lenses with wide apertures (e.g., f/1.4 or f/1.8) are essential for gathering more light in dimly lit environments. Look for cameras with effective autofocus systems that perform well in low-contrast situations. External flashes or continuous lights can be helpful for controlling and shaping the light.\n\nMixed (Both Indoors and Outdoors): If you'll be shooting in a mix of indoor and outdoor environments, versatility is key. Look for cameras with a wide ISO range to handle both low-light and bright conditions. Lenses with a variable aperture range (e.g., f/2.8-f/4) can provide flexibility for different lighting situations. Consider investing in lenses with image stabilization to compensate for camera shake in low-light settings. External flashes with TTL (Through The Lens) metering can help balance the exposure between the subject and background in mixed lighting conditions.",
      options: [
        { label: 'Mostly Outdoors (Natural Light)', value: 'Outdoors' },
        { label: 'Mostly Indoors (Artificial Light)', value: 'Indoors' },
        { label: 'Mixed (Both Indoors and Outdoors)', value: 'Mixed' },
      ],
    },
    {
      name: 'Mobility Requirements',
      description: "Your mobility requirements play a crucial role in determining the ideal photography gear. Here's a brief guide for each option:\n\nHighly Portable (Frequent Travel, Hiking): If you frequently travel or engage in outdoor activities like hiking, prioritize lightweight and compact gear. Consider mirrorless cameras or high-end point-and-shoot cameras that offer excellent image quality in a small form factor. Look for lenses with a compact design and a versatile focal range to minimize the need for multiple lenses. Invest in a sturdy and lightweight tripod or monopod for stability on the go.\n\nModerately Portable (Occasional Travel, Short Trips): If you occasionally travel or take short trips, you have more flexibility in terms of gear size and weight. A mid-range DSLR or mirrorless camera with a few versatile lenses can be a good choice. Consider a camera bag or backpack with good padding and organization to protect your gear during transport. A travel tripod that offers a balance of stability and portability can be helpful.\n\nStationary (Studio, Home Use): If you primarily shoot in a studio or at home, portability may be less of a concern. You can opt for larger and more advanced camera systems, such as full-frame DSLRs or high-end mirrorless cameras. Consider investing in a variety of prime and zoom lenses to cover different focal lengths and apertures. A sturdy tripod and studio lighting equipment, such as continuous lights or strobes, can greatly enhance your photography setup.",
      options: [
        { label: 'Highly Portable (Frequent Travel, Hiking)', value: 'HighlyPortable' },
        { label: 'Moderately Portable (Occasional Travel, Short Trips)', value: 'ModeratelyPortable' },
      ],
    },
    {
      name: 'Budget',
      description: "Your budget is a significant factor in determining the photography gear you can acquire. Here's a brief guide for each option:\n\nEntry-level (Budget-friendly): If you're just starting or have a limited budget, entry-level gear can still provide good results. Look for affordable DSLR or mirrorless cameras from reputable brands. Kit lenses that come bundled with the camera can be a cost-effective starting point. Consider third-party lenses or older models to save costs. Invest in essential accessories like a basic tripod, memory cards, and a camera bag.\n\nMid-range (Balanced Performance and Cost): If you have a moderate budget and seek a balance between performance and cost, mid-range gear offers advanced features and improved image quality. Consider enthusiast-level DSLR or mirrorless cameras with better sensors, autofocus systems, and low-light performance. Invest in higher-quality lenses with wider apertures and better optics. Look for accessories like a versatile tripod, external flash, and editing software.\n\nHigh-end (Professional, Advanced Features): If you have a substantial budget or require professional-grade equipment, high-end gear offers the best performance and advanced features. Consider full-frame DSLR or mirrorless cameras with top-of-the-line sensors, fast autofocus, and extensive customization options. Invest in premium lenses with superior sharpness, bokeh, and build quality. Acquire specialized accessories like high-end tripods, wireless flash systems, and professional-grade lighting equipment.",
      options: [
        { label: 'Entry-level (Budget-friendly)', value: 'EntryLevel' },
        { label: 'Mid-range (Balanced Performance and Cost)', value: 'MidRange' },
        { label: 'High-end (Professional, Advanced Features)', value: 'HighEnd' },
      ],
    },
  ];
  
  export default categories;