import React from 'react';
import './App.css';
import PhotoGearWizard from './components/PhotoGearWizard';
import ReactGA from 'react-ga4';

// Initialize react-ga4
ReactGA.initialize('G-7NSTV7X5NH');


function App() {
  return (
    <div className="App">
      <h1 className="pageTitle">PhotoGear Wizard</h1>
      <footer className="byline">
        Powered by <a href="https://aligstudios.com" target="_blank" rel="noopener noreferrer">Ali G Studios</a>
      </footer>
      <PhotoGearWizard />
      <footer className="byline">
        Developed by <a href="https://iterative.day" target="_blank" rel="noopener noreferrer">iterative.day</a>
      </footer>
    </div>
  );
}

export default App;