export const streamlinedRecs = {
    camera: {
      'EntryLevel': {
        'HighlyPortable': 'Entry-Level mirrorless camera',
        'ModeratelyPortable': 'Entry-Level DSLR camera',
      },
      'MidRange': {
        'HighlyPortable': 'Mid-Range mirrorless camera',
        'ModeratelyPortable': 'Mid-Range DSLR camera',
      },
      'HighEnd': {
        'HighlyPortable': 'High-end mirrorless camera',
        'ModeratelyPortable': 'High-end DSLR camera',
      },
    },
    lens: {
      People: {
        'EntryLevel': 'Kit zoom lens',
        'MidRange': 'Versatile zoom lens',
        'HighEnd': 'Premium zoom lens',
      },
      Nature: {
        'EntryLevel': 'Wide-angle lens',
        'MidRange': 'Wide-angle and telephoto lenses',
        'HighEnd': 'Premium wide-angle and telephoto lenses',
      },
      Urban: {
        'EntryLevel': 'Standard prime lens',
        'MidRange': 'Wide-angle prime lens',
        'HighEnd': 'Professional wide-angle zoom lens',
      },
      SportsAction: {
        'EntryLevel': 'Telephoto zoom lens',
        'MidRange': 'Fast telephoto zoom lens',
        'HighEnd': 'Professional sports zoom lens',
      },
      StillLife: {
        'EntryLevel': 'Macro prime lens',
        'MidRange': 'Macro lens with image stabilization',
        'HighEnd': 'Professional macro lens',
      },
      // ... (other subject matters)
    },
    lighting: {
      'Outdoors': 'Natural light',
      'Indoors': 'Continuous lighting kit',
      Mixed: 'On-camera flash and reflector',
    },
    tripod: {
      'HighlyPortable': 'Lightweight travel tripod',
      'ModeratelyPortable': 'Sturdy aluminum tripod',
    },
  };
  
  export function getStreamlinedRecs(selectedOptions) {
    const {
      'Subject Matter': subjectMatter,
      'Lighting Environment': lightingEnvironment,
      'Mobility Requirements': mobilityRequirements,
      Budget: budget,
    } = selectedOptions;
  
    // Safely access the camera recommendation using optional chaining and provide a default value if undefined
    const camera =
      streamlinedRecs.camera?.[budget]?.[mobilityRequirements] ??
      'Default camera recommendation';
  
    // Safely access the lens recommendation using optional chaining and provide a default value if undefined
    const lens =
      streamlinedRecs.lens?.[subjectMatter]?.[budget] ??
      'Default lens recommendation';
  
    // Safely access the lighting recommendation using optional chaining and provide a default value if undefined
    const lighting =
      streamlinedRecs.lighting?.[lightingEnvironment] ??
      'Default lighting recommendation';
  
    // Safely access the tripod recommendation using optional chaining and provide a default value if undefined
    const tripod =
      streamlinedRecs.tripod?.[mobilityRequirements] ??
      'Default tripod recommendation';
    
    console.log('Camera:', camera);
    console.log('Lens:', lens);
    console.log('Lighting:', lighting);
    console.log('Tripod:', tripod);
    
    return {
      camera,
      lens,
      lighting,
      tripod,
    };
  }